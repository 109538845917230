import React, { useState } from 'react';
import {
  Box,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { FaHotel, FaCarAlt, FaTicketAlt, FaCalendarAlt } from 'react-icons/fa';
import { groupByDay, mapCategoryToKey } from '../utils';

const getCategoryIcon = (category) => {
  switch (category) {
    case 'accommodation':
      return <FaHotel />; // Hotel icon for accommodations
    case 'transportation rental':
      return <FaCarAlt />; // Car icon for transportation rental
    case 'transportation ticket':
      return <FaTicketAlt />; // Ticket icon for transportation tickets
    case 'activity reservation':
      return <FaCalendarAlt />; // Calendar icon for activity reservations
    // Add more as needed
    default:
      return null; // Or a generic placeholder icon if necessary
  }
};



const ItineraryTable = ({ itinerary }) => {
  const groupedItems = groupByDay(itinerary);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Get unique dates for header columns
  const allDates = Object.keys(groupedItems).sort();

  const handleItemClick = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const ImagePreviewModal = () => (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image src={selectedItem?.downloadUrl} alt={selectedItem?.name} maxW="full" />
          <Box mt={4}>
              <Text><strong>Name:</strong> {selectedItem?.name}</Text>
              <Text><strong>Code:</strong> {selectedItem?.code}</Text>
              <Text><strong>Price:</strong> {selectedItem?.price}</Text>
              <Text><strong>Currency:</strong> {selectedItem?.currency}</Text>
              <Text><strong>Country:</strong> {selectedItem?.country}</Text>
              <Text><strong>Postal Code:</strong> {selectedItem?.postal_code}</Text>
              <Text><strong>Address:</strong> {selectedItem?.address}</Text>
              <Text><strong>Start Date:</strong> {selectedItem?.start_date}</Text>
              <Text><strong>End Date:</strong> {selectedItem?.end_date}</Text>
              <Text><strong>Description:</strong> {selectedItem?.description}</Text>
              <Text><strong>Cancelation Policy:</strong> {selectedItem?.cancelation_policy}</Text>
              <Text><strong>Category:</strong> {selectedItem?.category}</Text>
            </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderItemsForDate = (category, date) => {
    
    return groupedItems[date][category]
      .map((item, idx) => (
        <Text 
        key={idx}
        fontSize="sm"
        cursor="pointer"
        onClick={() => handleItemClick(item)}
        _hover={{ textDecoration: 'underline' }}
      >
        {getCategoryIcon(item.category)}
        {item.name}
      </Text>
      ));
  };

  if(Object.keys(groupedItems).length > 1){

  return (
    <Box width="full" overflowX="auto">
      {ImagePreviewModal()}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Category</Th>
            {allDates.map(date => (
              <Th key={date}>{date}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {['accommodation', 'transportation', 'activities'].map(category => (
            <Tr key={category}>
              <Th>{category.charAt(0).toUpperCase() + category.slice(1)}</Th>
              {allDates.map(date => (
                <Td key={`${category}-${date}`}>
                  {renderItemsForDate(category, date)}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
  }
  else{
    return <></>;
  }
};

export default ItineraryTable;