import React, { useState } from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Center,
  VStack,
  Input,
  useToast,
  Spacer,
  Container
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { AiOutlineMail } from 'react-icons/ai';
import { FaMicrosoft } from 'react-icons/fa';
import { signInWithGoogle, signInWithEmailLink, signInWithMicrosoft } from './firebase.utils'; // These functions need to be implemented in firebase utils

const Login = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();

  const handleEmailSignIn = () => {
    signInWithEmailLink(email, (error, result) => {
      if (error) {
        toast({
          title: 'Error signing in with email',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Success',
          description: 'Check your email for the sign in link',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Flex direction="column" align="center" justify="center" h="100vh">
      {/* Header */}
      <Flex w="full" p={5} bg="blue.600" align="center">
        <Container maxW="container.xl">
          <Heading size="lg" color="white">
            ItineraryLinker
          </Heading>
        </Container>
      </Flex>

      {/* Content */}
      <Center w="full" flex="1" p={5}>
        <Container maxW="container.xl">
          <Flex
            direction={['column', 'row']}
            align="center"
            justify="space-around"
            h="full"
            gap={10}
            p={{ base: 2, md: 5 }}
            textAlign="center"
          >
            <Box flex="1" mb={[5, 0]}>
              <Heading mb={4}>Your Travel Content into Profitable Itineraries</Heading>
              <Text fontSize="lg" color="gray.600">
              Transform blogs, videos, or anything into structured, affiliate-rich itineraries, maximizing your revenue on TikTok, Instagram and YouTube.
              </Text>
              <video loading="lazy" muted="muted" src="/travel-planner.mp4" type="video/mp4" autoplay="autoplay" loop="loop" width={350}></video>
            </Box>

            <VStack
              p={8}
              spacing={6}
              bg="white"
              borderColor="gray.200"
              borderWidth="1px"
              rounded="xl"
              boxShadow="xl"
              w="full"
              maxW={['full', 'md']}
              align="stretch"
            >
              <Button leftIcon={<FcGoogle />} variant="outline" onClick={() => signInWithGoogle(toast)} colorScheme="blue">
                Continue with Google
              </Button>
              <Button leftIcon={<FaMicrosoft />} variant="outline" onClick={() => signInWithMicrosoft(toast)} colorScheme="teal">
                Continue with Microsoft
              </Button>
              <Input placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Button leftIcon={<AiOutlineMail />} variant="solid" onClick={handleEmailSignIn} bg="#2b6cb0" color="white" _hover={{ bg: "#2a528a" }}>
                Sign in with Email
              </Button>
            </VStack>
          </Flex>
        </Container>
      </Center>

      {/* Footer */}
      <Flex w="full" p={4} bg="blue.600" align="center">
        <Container maxW="container.xl">
          <Text color="white" textAlign="center">
            &copy; {new Date().getFullYear()} ItineraryLinker. All Rights Reserved.
          </Text>
        </Container>
      </Flex>
    </Flex>
  );
};

export default Login;