// AdminComponent.js
import React from 'react';
import {
  Avatar,
  Box,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  DrawerCloseButton,
  VStack,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { auth } from '../firebase.utils'; 

const AdminComponent = ({ children, user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSignOut = () => {
    auth.signOut(); // Calls Firebase auth signOut method
  };

  return (
    <Flex minHeight="100vh" direction="column" justify="space-between">
      {/* Header */}
      <Flex
        as="header"
        align="center"
        justifyContent="space-between"
        wrap="wrap"
        p={4}
        bg="blue.600"
        color="white"
      >
        <Flex align="center">
          <IconButton
            icon={<HamburgerIcon />}
            onClick={onOpen}
            variant=""
            aria-label="open menu"
            size="sm" // Added size prop to make it small
            // Removed display prop to keep it visible all the time
          />
          <Heading as="h1" size="lg" ml={3}>
            TravelPlanning
          </Heading>
        </Flex>
        <Menu>
          <MenuButton as={Button} variant="ghost" mr={2}>
            <Avatar size="sm" src={user?.photoURL} />
          </MenuButton>
          <MenuList  bg="blue.600">
            <MenuItem onClick={handleSignOut} bg="blue.600">Sign Out</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {/* Drawer for mobile nav */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <VStack mt={10} spacing={4}>
              <ChakraLink as={Link} to="/admin/assessments" onClick={onClose}>
                Assessments
              </ChakraLink>
              <ChakraLink as={Link} to="/admin/assessments_data" onClick={onClose}>
                Assessments Data
              </ChakraLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Main Content Area */}
      {/* Main Content Area */}
      <Flex direction="column" flex="1" overflowY="auto"> {/* Removed 'width="full"' which is not necessary */}
        <Box as="main" flex="1" p={4}> {/* Removed 'width="full"' and added flex="1" */}
          {children}
        </Box>
      </Flex>

        {/* Footer */}
        <Box as="footer" bg="blue.600" color="white" py={2}>
        <Flex align="center" justify="space-between" p={4}>
          <Text>&copy; {new Date().getFullYear()} TravelPlanning. All Rights Reserved.</Text>
        </Flex>
      </Box>

    </Flex>
  );
};

export default AdminComponent;