import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Input,
  VStack,
  useToast,
  Icon,
  Image,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  ModalCloseButton,
  IconButton,
  Box,
  FormControl,
  useDisclosure,
  FormLabel,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineCloudUpload, AiOutlineEye, AiOutlineDelete } from 'react-icons/ai';
import { uploadImagesAndGetMetadata, getImageMetadataForTrip, deleteImageAndMetadata, createNewTrip } from '../firebase.utils';
import ItineraryTable from './ItineraryTable';
import BudgetSummary from './BudgetSummary';



const ImageUploader = ({ accountId }) => {
  const { tripId } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  const fetchImages = useCallback(async () => {
    if(!tripId){
      setImages([]);
      return;
    }
    try {
      const metadata = await getImageMetadataForTrip(accountId, tripId);
      setImages(metadata);
    } catch (error) {
      toast({
        title: 'Error fetching images',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [accountId, tripId, toast]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const uploadFiles = async () => {
    if (files.length === 0) {
      toast({
        title: 'No files selected',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    setIsUploading(true);
    try {
      const effectiveTripId = tripId || await createNewTrip(accountId);
      const metadata = await uploadImagesAndGetMetadata(files, accountId, effectiveTripId);
      if (metadata.length > 0) {
        toast({
          title: 'Files uploaded successfully',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        fetchImages();
        navigate(`/trip/${effectiveTripId}`);
      }
    } catch (error) {
      toast({
        title: 'Error uploading files',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const deleteImage = async (fileId, fileName) => {
    try {
      await deleteImageAndMetadata(accountId, tripId, fileId, fileName);
      toast({
        title: 'Image deleted successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      fetchImages();
    } catch (error) {
      toast({
        title: 'Error deleting image',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const ImagePreviewModal = () => (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image src={selectedImage?.downloadUrl} alt={selectedImage?.name} maxW="full" />
          <Box mt={4}>
              <Text><strong>Name:</strong> {selectedImage.name}</Text>
              <Text><strong>Code:</strong> {selectedImage.code}</Text>
              <Text><strong>Price:</strong> {selectedImage.price}</Text>
              <Text><strong>Currency:</strong> {selectedImage.currency}</Text>
              <Text><strong>Country:</strong> {selectedImage.country}</Text>
              <Text><strong>Postal Code:</strong> {selectedImage.postal_code}</Text>
              <Text><strong>Address:</strong> {selectedImage.address}</Text>
              <Text><strong>Start Date:</strong> {selectedImage.start_date}</Text>
              <Text><strong>End Date:</strong> {selectedImage.end_date}</Text>
              <Text><strong>Description:</strong> {selectedImage.description}</Text>
              <Text><strong>Cancelation Policy:</strong> {selectedImage.cancelation_policy}</Text>
              <Text><strong>Category:</strong> {selectedImage.category}</Text>
            </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <VStack spacing={4} align="stretch">
      { images.length > 0 && (
        <>
        <BudgetSummary expenses={images} tripId={tripId} accountId={accountId} />
        <ItineraryTable itinerary={images} />
        </>
      )}
      <FormControl>
        <FormLabel htmlFor="file-input">
          <Box display="flex" alignItems="center" p={2} borderWidth="1px" borderRadius="md">
            <Icon as={AiOutlineCloudUpload} w={6} h={6} mr={2} />
            <Box flex="1" textAlign="left">Select Files</Box>
          </Box>
        </FormLabel>
        <Input
          id="file-input"
          type="file"
          multiple
          onChange={handleFileChange}
          display="none"
        />
      </FormControl>
      <Button
        leftIcon={<AiOutlineCloudUpload />}
        colorScheme="teal"
        variant="solid"
        isLoading={isUploading}
        loadingText="Uploading"
        onClick={uploadFiles}
        disabled={isUploading}
      >
        Upload Files
      </Button>

      {/* Image Grid */}
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
        {images.map((image) => (
          <Box key={image.id} borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Image
              src={image.downloadUrl}
              alt={image.name}
              boxSize="200px"
              objectFit="cover"
              onClick={() => {
                setSelectedImage(image);
                onOpen();
              }}
              cursor="pointer"
            />
            <Box p={2}>
              <IconButton
                icon={<AiOutlineEye />}
                onClick={() => {
                  setSelectedImage(image);
                  onOpen();
                }}
                aria-label="Preview image"
                isRound
              />
              <IconButton
                icon={<AiOutlineDelete />}
                onClick={() => deleteImage(image.id, image.name)}
                aria-label="Delete image"
                colorScheme="red"
                ml={2}
                isRound
              />
            </Box>
          </Box>
        ))}
      </SimpleGrid>

      {/* Image Preview Modal */}
      {isOpen && <ImagePreviewModal />}
    </VStack>
  );
};

export default ImageUploader;