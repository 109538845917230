// BudgetSummary.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { convertCurrencyToUSD } from '../utils';
import { getParticipants } from '../firebase.utils';
import ExpenseListContent from './ExpenseListContent';
import UpdateExpenseContent from './UpdateExpenseContent';

const BudgetSummary = ({ expenses, accountId, tripId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [participants, setParticipants] = useState([]);
  const [modalContent, setModalContent] = useState('list'); // 'list' or 'update'
  const [selectedExpense, setSelectedExpense] = useState(null);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const fetchedParticipants = await getParticipants(accountId, tripId);
        setParticipants(fetchedParticipants);
      } catch (error) {
        console.error("Error fetching participants:", error);
      }
    };

    fetchParticipants();
  }, [accountId, tripId]);

  const totalBudgetUSD = expenses.reduce((acc, expense) => {
    return acc + convertCurrencyToUSD(expense.price, expense.currency);
  }, 0);

  const zeroPriceExpenses = expenses.filter(expense => expense.price === 0);

  const handleOpenList = () => {
    setModalContent('list');
    onOpen();
  };

  const handleOpenUpdate = (expense) => {
    setSelectedExpense(expense);
    setModalContent('update');
  };

  const handleModalClose = () => {
    setSelectedExpense(null);
    setModalContent('list');
    onClose();
  };

  return (
    <Box>
      <Heading size="lg">Budget Summary</Heading>
      { totalBudgetUSD > 0 && (
      <Text fontSize="xl">Total expenses in USD: ${totalBudgetUSD.toFixed(2)}</Text>
      )}
      {  zeroPriceExpenses > 0 && (
      <Button colorScheme="blue" onClick={handleOpenList} mt={4}>
        {zeroPriceExpenses.length ? 'Update Expenses' : 'View Expenses'}
      </Button>)}
      {zeroPriceExpenses.length > 0 && (
        <Alert status="warning" mt={4}>
          <AlertIcon as={WarningTwoIcon} />
          <AlertDescription>
            There are expenses with missing costs. Please update.
          </AlertDescription>
        </Alert>
      )}

      <Modal isOpen={isOpen} onClose={handleModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          {modalContent === 'list' ? (
            <ExpenseListContent
              expenses={zeroPriceExpenses}
              onEdit={handleOpenUpdate}
            />
          ) : (
            <UpdateExpenseContent
              expense={selectedExpense}
              onClose={handleModalClose}
              accountId={accountId}
              tripId={tripId}
              participants={participants}
            />
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BudgetSummary;