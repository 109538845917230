// UpdateExpenseContent.js
import React, { useState, useEffect } from 'react';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Image,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { updateExpense, addParticipant } from '../firebase.utils';

const UpdateExpenseContent = ({
  expense,
  onBack,
  participants,
  accountId,
  tripId
}) => {
  const [price, setPrice] = useState(expense.price);
  const [currency, setCurrency] = useState(expense.currency);
  const [paidBy, setPaidBy] = useState(expense.paidBy || '');
  const [newParticipant, setNewParticipant] = useState('');
  const toast = useToast();

  useEffect(() => {
    if (expense) {
      setPrice(expense.price);
      setCurrency(expense.currency);
      setPaidBy(expense.paidBy || '');
    }
  }, [expense]);

  const handleSubmit = async () => {
    try {
      // If a new participant was added, add to Firestore and update local state
      if (newParticipant && !participants.includes(newParticipant)) {
        await addParticipant(accountId, tripId, newParticipant);
        // Update the participants list in the parent component
      }

      // Prepare updated expense data
      const updatedExpenseData = {
        ...expense,
        price: parseFloat(price) || 0,
        currency,
        paidBy: newParticipant || paidBy
      };

      // Update the expense in Firestore
      await updateExpense(accountId, tripId, expense.id, updatedExpenseData);

      // Show success message
      toast({
        title: 'Success',
        description: 'Expense updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });

      onBack();
    } catch (error) {
      console.error('Error updating expense:', error);
      toast({
        title: 'Error',
        description: 'There was a problem updating the expense.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <>
      <ModalHeader>
        <IconButton
          icon={<ArrowBackIcon />}
          variant="ghost"
          onClick={onBack}
          aria-label="Back to expenses list"
        />
        Update Expense Details
      </ModalHeader>
      <ModalBody>
        {expense.downloadUrl && (
          <Image src={expense.downloadUrl} alt={expense.name} mb={4} boxSize="100px" objectFit="cover" />
        )}
        <Text fontWeight="bold" mb={2}>{expense.name}</Text>
        <Text fontSize="sm" mb={4}>{expense.description}</Text>
        <FormControl>
          <FormLabel htmlFor="price">Price</FormLabel>
          <Input id="price" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel htmlFor="currency">Currency</FormLabel>
          <Select id="currency" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD">USD</option>
            <option value="MXN">MXN</option>
            <option value="CLP">CLP</option>
            {/* Add more currency options as needed */}
          </Select>
        </FormControl>
        <FormControl mt={4}>
          <FormLabel htmlFor="paidBy">Paid by</FormLabel>
          <Input id="paidBy" value={paidBy} onChange={(e) => setPaidBy(e.target.value)} list="participant-names" />
          <datalist id="participant-names">
            {participants.map((p) => (
              <option key={p} value={p}>{p}</option>
            ))}
          </datalist>
        </FormControl>
        <FormControl mt={4}>
          <FormLabel htmlFor="new-participant">Add new participant</FormLabel>
          <Input id="new-participant" placeholder="Type new participant name" value={newParticipant} onChange={(e) => setNewParticipant(e.target.value)} />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" onClick={handleSubmit}>Save</Button>
        <Button onClick={onBack}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default UpdateExpenseContent;