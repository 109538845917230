// ExpenseListContent.js
import React from 'react';
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Button,
  Box,
  Text,
  Image,
} from '@chakra-ui/react';

const ExpenseListContent = ({ expenses, onEdit }) => {
  return (
    <>
      <ModalHeader>Expenses with Missing Costs</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          {expenses.map((expense) => (
            <Box key={expense.id} borderWidth="1px" borderRadius="lg" p={4} display="flex" alignItems="center">
              <Box flex="1">
                <Text fontWeight="bold">{expense.name}</Text>
                <Text fontSize="sm">{expense.description}</Text>
              </Box>
              {expense.downloadUrl && (
                <Image src={expense.downloadUrl} alt={expense.name} boxSize="50px" objectFit="cover" mr={4} />
              )}
              <Button colorScheme="blue" onClick={() => onEdit(expense)}>
                Modify
              </Button>
            </Box>
          ))}
        </Stack>
      </ModalBody>
    </>
  );
};

export default ExpenseListContent;