import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react'; 
import { ChakraProvider, Center, Spinner } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Configuration from './admin/Configuration';
import AdminComponent from './admin/AdminComponent';
import Login from './Login';
import { onAuthStateChanged } from 'firebase/auth';
import {auth, getUserData } from './firebase.utils';
import LandingPage from './LandingPage';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accountId, setAccountId] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        console.log('user', user);
        const userData = await getUserData(user.uid);
        if(userData){
          console.log(userData);
          setAccountId(userData.accountId || user.uid);
        }
        else{
          setAccountId(user.uid);
        }
      } else {
        setAccountId(null);
      }
      console.log(user);
      setLoading(false);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [])
  
  if (loading) { // Show loading spinner while determining authentication state
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  };

  return (
    <ChakraProvider>
       <Router>
          <Routes>
            <Route path="/admin/configuration" element={
              user ? (
              <AdminComponent user={user}>
                <Configuration user={user} />
              </AdminComponent>) :  <Login />
            }/>
            <Route path="/" element={
              user ? (
              <AdminComponent user={user}>
                <Configuration user={user} accountId={accountId} />
              </AdminComponent>) :  <Login />
            }/>
              <Route path="/trip/:tripId" element={
              user ? (
              <AdminComponent user={user}>
                <Configuration user={user} accountId={accountId} />
              </AdminComponent>) :  <Login />
            }/>
          </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
