import { parse, format, isValid, isWithinInterval } from 'date-fns';



import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

export const convertPdfToImage = async (file) => {
  const pdf = await getDocument(await file.arrayBuffer()).promise;
  const numPages = Math.min(pdf.numPages, 5); // Limit to 5 pages

  const canvases = await Promise.all(
    Array.from({ length: numPages }, async (_, i) => {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });

      const canvas = document.createElement('canvas');
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const context = canvas.getContext('2d');

      await page.render({ canvasContext: context, viewport }).promise;
      return canvas;
    })
  );

  // Create a single canvas to combine all pages
  const totalHeight = canvases.reduce((sum, canvas) => sum + canvas.height, 0);
  const combinedCanvas = document.createElement('canvas');
  combinedCanvas.width = Math.max(...canvases.map(canvas => canvas.width));
  combinedCanvas.height = totalHeight;
  const combinedContext = combinedCanvas.getContext('2d');

  let yOffset = 0;
  canvases.forEach((canvas) => {
    combinedContext.drawImage(canvas, 0, yOffset);
    yOffset += canvas.height;
  });

  return new Promise((resolve) => {
    combinedCanvas.toBlob((blob) => {
      resolve(new File([blob], 'combined.jpg', { type: 'image/jpeg' }));
    }, 'image/jpeg');
  });
};


const exchangeRates = {
    // Example rates: Base currency is USD
    USD: 1,
    EUR: 0.85,
    GBP: 0.75,
    CLP: 0.0011,
    MXN: 0.052
  };
  
  export const convertCurrencyToUSD = (amount, fromCurrency) => {
    // Assuming a fixed conversion rate for simplicity. In practice, this should be dynamic.
    const rate = exchangeRates[fromCurrency] || 1; // Fall back to 1 if currency not found
    const amountInUSD = amount*rate;
    return amountInUSD;
  };

export const parseDate = (dateString) => {
    // Define the expected date format
    const dateFormat = 'dd/MM/yyyy HH:mm';
    // Parse the date string using the defined format
    const parsedDate = parse(dateString, dateFormat, new Date());
  
    // Check if the parsed date is valid
    if (!isValid(parsedDate)) {
      console.error(`Invalid date string passed to parseDate: ${dateString}`);
      return null; // Return null or an appropriate fallback Date object
    }
  
    return parsedDate;
  };
  export const formatDate = (date) => {
    // Format the date to a human-readable string
    return date;
  };
export const groupByDay = (items) => {
  // Groups the items by day
  const groups = {};
  items.forEach((item) => {
    if(!!item.start_date){
    const startDate = parseDate(String(item.start_date));
    const endDate = parseDate(String(item.end_date));
    const dateKey = format(startDate, 'yyyy-MM-dd');
    const dateKey2 = format(endDate, 'yyyy-MM-dd');
    // Accommodate to the list of items for the same day
    if (!groups[dateKey]) {
      groups[dateKey] = {
        accommodation: [],
        transportation: [],
        activities: [],
      };
    }
    if (!groups[dateKey2]) {
        groups[dateKey2] = {
          accommodation: [],
          transportation: [],
          activities: [],
        };
      }
    const categoryKey = mapCategoryToKey(item.category);
    groups[dateKey][categoryKey].push(item);
    groups[dateKey2][categoryKey].push(item);
    }
  });
  return groups;
};

export const mapCategoryToKey = (category) => {
  // Maps the category from item to the key used in groups object
  switch (category) {
    case 'accommodation':
      return 'accommodation';
    case 'transportation rental':
    case 'transportation ticket':
      return 'transportation';
    case 'activity reservation':
      return 'activities';
    default:
      return 'others';
  }
};