// src/serviceWorkerRegistration.js

// Function to check if the service worker can be found. If the service worker can't be found, it's likely a different app. Reload the page.
function checkValidServiceWorker(swUrl) {
    fetch(swUrl)
      .then(response => {
        const contentType = response.headers.get('content-type');
        // Ensure service worker exists, and that we really are getting a JS file.
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          registerValidSW(swUrl);
        }
      })
      .catch(() => {
        console.log('No internet connection found. App is running in offline mode.');
      });
  }
  
  // Function to update the service worker. It logs the status of the service worker and reloads the page when a new service worker will take control.
  export function registerValidSW(swUrl) {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // New content is available and will be used when all tabs for this page are closed.
                console.log('New content is available and will be used when all tabs for this page are closed.');
                // Execute callback to possibly show a confirmation message to user
              } else {
                // Content is cached for offline use.
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  }
  
  // Function to register the service worker. It checks the compatibility and different cases to handle if service worker can't be found and update scenarios.
  export function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Service worker won't work if PUBLIC_URL is on a different origin from what our page is served on. This might happen if a CDN is used to serve assets.
        return;
      }
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (navigator.serviceWorker.controller) {
          // If there's an active service worker running, attempt to update it.
          registerValidSW(swUrl);
        } else {
          checkValidServiceWorker(swUrl);
        }
  
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA'
          );
        });
      });
    }
  }
  
  // Function to unregister the service worker.
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
    }
  }